import { css, FlattenSimpleInterpolation } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import {
  DirectionKey,
  FontBreakpoints,
  FontSizeBodyKey,
  FontSizeHeadingKey,
  FontWeightKey,
  IHasAlignment,
  IHasColor,
  IHasWidth,
  IHiddenProps,
  ILayoutControl,
  MediaMapResult,
  ObjectValues,
  StyledCSS,
  TBreakpointKeys,
  THasAlignments,
  XAlignKey
} from '@belong/types';
import { isObject, isUndefined, isNull, isNumber, hasValue } from './value';
import {
  ALIGNMENT,
  BREAKPOINTS,
  COLOURS,
  DIRECTION,
  FLEX_ALIGN,
  FLEX_ALIGN_REVERSE,
  FOCUS_RING_WIDTH,
  FONT_NAME,
  FONT_WEIGHT
} from './variables';

export const convertWeight: Record<FontWeightKey, string> = {
  BOLD: 'Bold',
  SEMI_BOLD: 'Semibold',
  REGULAR: 'Regular'
};

export const FONT_VARIANTS = [
  { ext: 'eot', type: 'embedded-opentype' },
  { ext: 'woff2', type: 'woff2' },
  { ext: 'ttf', type: 'truetype' },
  { ext: 'woff', type: 'woff' }
];

export const fontFace = (
  staticFolder: string,
  name: ObjectValues<typeof FONT_NAME>,
  weight: FontWeightKey
): FlattenSimpleInterpolation => {
  const fontBase = `${staticFolder}/fonts/${name}-${convertWeight[weight]}`;
  return css`
    @font-face {
      font-family: ${name};
      font-display: fallback;
      font-weight: ${FONT_WEIGHT[weight]};
      src: ${FONT_VARIANTS.map(({ ext, type }) => `url('${fontBase}.${ext}') format('${type}')`).join(',')};
    }
  `;
};
export const spacingMixin = ({ hasMargin, hasPadding }: any): any => css`
  ${hasMargin && !isObject(hasMargin)
    ? `margin: ${toCSSUnits(hasMargin)}`
    : mediaMap(
        hasMargin,
        (margin: any) => css`
          margin: ${toCSSUnits(margin)};
        `
      )};
  ${hasPadding && !isObject(hasPadding)
    ? `padding: ${toCSSUnits(hasPadding)}`
    : mediaMap(
        hasPadding,
        (padding: any) => css`
          padding: ${toCSSUnits(padding)};
        `
      )};
`;

/**
 * convert number/string to rem
 * */
const toCSSUnits = (value: any, unit = 'rem'): string => {
  if (isNumber(value)) {
    return value.toString() + unit;
  }
  return value;
};

type MediaExtensionFn = (cssString: string | TemplateStringsArray, ...args: any[]) => any;

export type MediaExtensions = Record<TBreakpointKeys, MediaExtensionFn>;

type MediaFn = (gte: TBreakpointKeys, lte?: TBreakpointKeys) => any;

export const media: MediaFn & MediaExtensions = breakpoint as MediaFn & MediaExtensions;

export const smallerThan = (breakpoint1: TBreakpointKeys, breakpoint2: TBreakpointKeys): boolean => {
  const breakpointKeys = ['xs', 'sm', 'md', 'lg', 'xl'];
  const bt1Idx = breakpointKeys.indexOf(breakpoint1);
  const bt2Idx = breakpointKeys.indexOf(breakpoint2);
  return bt1Idx < bt2Idx;
};
/**
 * Helper method to generate css for multiple breakpoints
 * @param {Object} values - object containing breakpoint data e.g. { sm: 300, lg: 500 }
 * @param {(valueProp) => css``} callback - function to output css for a given breakpoint
 * @return Styled CSS String
 *
 * @example
 * set width of div based on breakpoint
 * const getSizeProp = (props) => ({ size: props.size });
 * const Wrapper = styled.div.attrs(getSizeProp)`
 *  ${({ size }) => mediaMap(size, (size) => `width: ${size}px`;)}
 * `;
 *
 */
export const mediaMap = (values: any, callback?: any): MediaMapResult => {
  if (isUndefined(values) || isNull(values)) {
    return;
  }

  const results: any[] = [];

  if (isObject(values)) {
    Object.keys(values).forEach(key => {
      const value = values[key];
      if (hasValue(value) && key in BREAKPOINTS) {
        results.push(media(key as TBreakpointKeys)`${callback(value, key)}`);
      }
    });
  } else {
    results.push(callback(values));
  }

  if (results.length === 0) {
    return;
  }

  return results;
};

const getWidthCssString = (width: string): FlattenSimpleInterpolation => {
  switch (width) {
    case 'small':
      return css`
        width: 8rem;
      `;
    case 'medium':
      return css`
        width: 14.8rem;
      `;
    case 'large':
      return css`
        width: 19.4rem;
      `;
    case 'half':
      return css`
        width: 50%;
      `;
    case 'full':
      return css`
        width: 100%;
      `;
    default:
      return css`
        width: 100%;
      `;
  }
};

export const width = (props: IHasWidth): FlattenSimpleInterpolation => {
  return mediaMap(props.width, getWidthCssString);
};

export const hasAlignment = (props: IHasAlignment): MediaMapResult =>
  mediaMap(
    props.alignment,
    (alignment: THasAlignments) => css`
      text-align: ${alignment};
    `
  );

export const hasFlexAlignment = (props: IHasAlignment): MediaMapResult =>
  mediaMap(
    props.alignment,
    (alignment: XAlignKey) => css`
      justify-content: ${FLEX_ALIGN[alignment]};
    `
  );

export const hasFlexAlignmentItems = (props: IHasAlignment): MediaMapResult =>
  mediaMap(
    props.alignment,
    (alignment: XAlignKey) => css`
      align-items: ${FLEX_ALIGN[alignment]};
    `
  );

export const hasColor = (props: IHasColor): FlattenSimpleInterpolation => css`
  color: ${props.hasColor};
`;

export const isHidden = (props: IHiddenProps): MediaMapResult =>
  props.isHidden &&
  media(props.isHidden[0], props.isHidden[1])`
    display: none !important;
  `;

export const isFocused = (inset = false): FlattenSimpleInterpolation => css`
  outline: ${FOCUS_RING_WIDTH}rem solid ${COLOURS.BELONG_BLUE_DARK};
  outline-offset: ${inset ? '-0.4' : FOCUS_RING_WIDTH}rem;
`;

export const applyFlexLayout = ({ hasControl }: ILayoutControl, margin = '1.6rem'): StyledCSS => css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  ${mediaMap(hasControl, (config: [DirectionKey, XAlignKey, boolean]) => {
    const [direction = DIRECTION.row, align = ALIGNMENT.left, reverse = false] = config;

    switch (direction) {
      case 'row':
        return css`
          flex-direction: ${reverse ? 'row-reverse' : 'row'};
          align-items: ${reverse ? 'flex-end' : 'flex-start'};
          justify-content: ${reverse ? FLEX_ALIGN_REVERSE[align] : FLEX_ALIGN[align]};
          column-gap: ${margin};
        `;
      case 'column':
        return css`
          flex-direction: ${reverse ? 'column-reverse' : 'column'};
          justify-content: ${reverse ? 'flex-start' : 'flex-end'};
          align-items: ${FLEX_ALIGN[align]};
          row-gap: ${margin};
        `;

      default:
        return css``;
    }
  })}
`;

export const textShimmer = css`
  background: linear-gradient(230deg, #f4f4f4, #99b1ca);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  [data-platform*='Win'] & {
    padding-bottom: 1rem; // bounding box of the text doesn't extend beyond the baseline on windows so it cuts off the descenders
    margin-bottom: -1rem;
  }
`;

export const wh = (ratio: string): string[] => ratio.split(':');

export const getAspectRatio = (ratio: string): string => {
  const [w = 16, h = 9] = wh(ratio);
  return `${w} / ${h}`;
};

export const getPaddingTop = (ratio: string): string => {
  const [w = 16, h = 9] = wh(ratio);
  return `calc(${h} / ${w} * 100%);`;
};

export const grayScale = css`
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
`;

export const getBodyFont = (fontBreakpoint: FontBreakpoints, size: FontSizeBodyKey): StyledCSS => css`
  font-size: ${({ theme }): string => theme.fontSize.body[fontBreakpoint][size]};
  line-height: ${({ theme }): string => theme.lineHeight.body[fontBreakpoint][size]};
`;

export const getHeadingFont = (fontBreakpoint: FontBreakpoints, size: FontSizeHeadingKey): StyledCSS => css`
  font-size: ${({ theme }): string => theme.fontSize.heading[fontBreakpoint][size]};
  line-height: ${({ theme }): string => theme.lineHeight.heading[fontBreakpoint][size]};
`;
