import type { JwtPayload } from 'jwt-decode';

export const ACCESS_TOKEN_EMAIL_FIELD = 'https://auth.belong.com.au/email';
export const ID_TOKEN_USER_METADATA_FIELD = 'https://user_metadata';

export interface IAccessToken extends JwtPayload {
  [ACCESS_TOKEN_EMAIL_FIELD]: string;
  scope: string;
}

export interface IUserMeta {
  migrated: boolean;
  isMigratedToDigitalStack: boolean;
  contactRole: string;
  fixedOctaneId?: string;
  mobileOctaneId?: string;
  profileName: string;
  roleName?: string;
}

export interface IUserIdToken extends JwtPayload {
  email: string;
  email_verified: boolean;
  given_name: string;
  family_name: string;
  nickname?: string;
  name: string;
  picture?: string;
  updated_at: string;
  [ID_TOKEN_USER_METADATA_FIELD]: IUserMeta;
}

interface IAgentCustomAttributes {
  First_Name?: string;
  Email: string;
  User_ID: string;
}

export interface IAgentIdToken extends JwtPayload {
  custom_attributes?: IAgentCustomAttributes;
}

export interface IAgentCustomerCookie {
  accessToken: string;
  idToken: string;
  mobileNumber?: string;
  octaneId?: string;
  paymentToken?: string;
}

export enum USER_TYPE {
  AGENT = 'agent',
  CUSTOMER = 'customer'
}

export interface IPartnerIdToken extends JwtPayload {
  custom_attributes?: IPartnerCustomAttributes;
}

export enum USER_ROLE {
  AGENT = 'Inbound Sales',
  AGENT_SSBI = 'SSBI Inbound Sales'
}

export interface IPartnerCustomAttributes {
  First_Name: string;
  Last_Name: string;
  Email: string;
  User_ID: string;
  Contact_Role: USER_ROLE;
  Expiration_Time: string;
  Eve_ID: string;
  Profile_Name: string;
  User_AccountId: string;
  Role_Name: string;
}
