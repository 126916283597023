import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { FONT_WEIGHT, getHeadingFont, media, spacingMixin } from '@belong/themes';
import { IHeading, THeadingLevel, VARIANT_TO_HEADING_LEVEL } from '@belong/types';
import { Text } from '../Text';

// TODO: Eliminate the use of heading level to determine font style. This is a semantic error
export const getAsPropFromVariant = ({ as, variant }: IHeading): THeadingLevel => {
  if (as) {
    return as;
  }

  if (!variant || !VARIANT_TO_HEADING_LEVEL[variant]) {
    return VARIANT_TO_HEADING_LEVEL.large; // fallback
  }

  return VARIANT_TO_HEADING_LEVEL[variant];
};

/**
 * The `Heading` component is used to render headings.
 */
export const Heading = styled(Text).attrs((props: IHeading) => ({
  hasColor: props.hasColor,
  variant: props.variant || 'large',
  as: getAsPropFromVariant(props)
}))<IHeading>`
  ${spacingMixin}

  color: ${({ hasColor, theme }) => hasColor ?? theme.foregroundColorDark};

  text-transform: none;

  ${({ theme, variant }): FlattenSimpleInterpolation => {
    switch (variant) {
      case 'xSmall':
        return css`
          ${getHeadingFont('xs', 'xSmall')}
          letter-spacing: 0;
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};
          text-transform: none;

          ${media('md')`
            ${getHeadingFont('md', 'xSmall')}
        `}
        `;

      case 'small':
        return css`
          ${getHeadingFont('xs', 'small')}
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};
          letter-spacing: 0;

          ${media('md')`
            ${getHeadingFont('md', 'small')}
            letter-spacing: 0;
          `}
        `;

      case 'medium':
        return css`
          font-size: ${theme.fontSize.heading.xs.medium};
          ${getHeadingFont('xs', 'medium')}
          font-weight: ${FONT_WEIGHT.BOLD};
          letter-spacing: 0.2px;

          ${media('md')`
          ${getHeadingFont('md', 'medium')}
            letter-spacing: 0.2px;
          `}
        `;

      default:
      case 'large':
        return css`
          ${getHeadingFont('xs', 'large')}
          font-weight: ${FONT_WEIGHT.BOLD};
          letter-spacing: 0.2px;

          ${media('md')`
            ${getHeadingFont('md', 'large')}
            letter-spacing: -0.5px;
          `}
        `;
    }
  }};
`;
