import { BORDER_RADIUS, COLOURS, FLAIR_COLOURS, FONT_COLOURS, isFocused } from '@belong/themes';
import { isFlairColor } from '@belong/themes/helpers/value';
import styled, { css } from 'styled-components';
import { Heading } from '@belong/ui-components';

const headingDimensions = css`
  width: 100%;
  height: 4rem;
`;

export const DropdownHeadingWrapper = styled.div`
  ${headingDimensions};
`;

export const DropdownHeading = styled(Heading).attrs({ variant: 'medium', as: 'h3' })`
  ${({ headingColor }) => {
    if (isFlairColor(headingColor)) {
      return css`
        background-image: ${FLAIR_COLOURS[headingColor]};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        display: initial;
      `;
    }
    return css`
      ${headingDimensions};
      color: ${COLOURS[headingColor]};
    `;
  }};
  border: 2px solid transparent;
  outline: none;
  border-radius: ${BORDER_RADIUS.CARD};

  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;

  &:focus {
    /* ${isFocused()}; */
    border: 0.2rem solid ${COLOURS.BELONG_BLUE_DARK};
    color: ${FONT_COLOURS.ACTIVE};
    outline: none;
  }
`;
