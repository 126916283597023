import styled, { css } from 'styled-components';

import { MaybeStyledCSS, StyledCSS } from '@belong/types';
import { FONT_WEIGHT, FONT_COLOURS, media, getBodyFont } from '@belong/themes';

import { Text } from '../Text';
import { ICopy } from './Copy.types';

export const Copy = styled(Text).attrs((props: ICopy) => ({
  hasColor: props.hasColor,
  variant: props.variant || 'medium',
  as: props.as ? props.as : 'p'
}))<ICopy>`
  ${({ isBold, hasColor }): MaybeStyledCSS =>
    isBold
      ? css`
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};
          color: ${hasColor === FONT_COLOURS.BASE && FONT_COLOURS.STRONG};
        `
      : css`
          font-weight: ${FONT_WEIGHT.REGULAR};

          > strong {
            font-weight: ${FONT_WEIGHT.SEMI_BOLD};
            color: ${hasColor === FONT_COLOURS.BASE && FONT_COLOURS.STRONG};
          }
        `}

  ${({ theme, hasColor }) =>
    !hasColor &&
    css`
      color: ${theme.foregroundColor};
      > strong {
        color: ${theme.foregroundColor};
      }
    `};

  ${({ variant, hasColor, theme }): StyledCSS => {
    switch (variant) {
      case 'small':
        return css`
          ${getBodyFont('xs', 'xSmall')}

          ${!hasColor &&
          css`
            &,
            & > strong {
              color: ${theme.foregroundColorLight};
            }
          `};

          ${media('md')`
            ${getBodyFont('md', 'xSmall')}
          `}
        `;

      default:
      case 'medium':
        return css`
          ${getBodyFont('xs', 'medium')}

          ${media('md')`
            ${getBodyFont('md', 'medium')}
          `}
        `;

      case 'large':
        return css`
          ${getBodyFont('xs', 'large')}

          ${media('md')`
          ${getBodyFont('md', 'large')}
        `}
        `;
    }
  }}
`;

Copy.displayName = 'Copy';
